@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 74%;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}

.hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .hed_right {
    justify-content: center;
  }
}

.hed_right .telwrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  .hed_right .telwrap {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .hed_right .telwrap {
    display: block;
  }
}

.hed_right .tel2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

@media only screen and (max-width: 640px) {
  .hed_right .tel2 {
    font-size: 1.25em;
    margin-right: 0;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_right .tel2 {
    font-size: 1.25em;
  }
}

.hed_right .tel2 a {
  color: #333;
  font-weight: bold;
  display: block;
}

.hed_right .tel_time {
  margin-right: 30px;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .hed_right .tel_time {
    margin-right: 10px;
    padding: 0;
  }
}

.hed_right .tel_time img {
  margin-right: 7px;
}

.hed_right .tel_time .tel2 {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.hed_right .tel_time .tel2 a {
  color: #333;
  font-weight: bold;
}

.hed_right .tel_time .time {
  font-size: 0.8em;
  display: block;
  background: #f29425;
  color: #ffffff;
  margin-top: 5px;
  padding: 2px 5px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
}

.hed_right .hed_cont a {
  background: url(../images/common/cont_btn_bg.png) no-repeat;
  background-size: cover;
  color: #ffffff;
  font-size: 1.3em;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .hed_right .hed_cont a {
    font-size: 1em;
    padding: 10px;
  }
}

.hed_right .hed_cont a img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .hed_right .hed_cont a img {
    max-width: calc(100% / 7);
  }
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header {
    display: block;
    padding: 20px 10px 20px;
  }
}

#header .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .wrap {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .wrap {
    display: block;
  }
}

#header #siteID {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

#hed_info .address {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  #hed_info .address {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1540px) {
  .navwrap {
    width: 100%;
  }
}

.hedwrap {
  border-bottom: 5px solid #fc3605;
}

#nav_global {
  transition: 0.5s ease-in-out;
  width: 100%;
  background: #f29425;
  padding: 5px 0;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
  width: 100%;
  border-left: 1px solid #fff;
}

#nav_global ul li:last-of-type {
  border-right: 1px solid #fff;
}

#nav_global ul li a {
  width: 100%;
  display: block;
  color: #ffffff;
  padding: 5px;
  font-weight: bold;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 240px;
  z-index: 100;
  display: block;
  background: white;
  text-align: center;
  left: 0;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #fff81f !important;
  font-size: 14px;
  border-bottom: none;
  border-left: none !important;
}

#nav_global ul ul li a {
  color: #333;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  #mainArea {
    margin: auto;
  }
}

#mainArea img {
  width: 100% !important;
}

#mainArea2 {
  background: url(../images/contents/main_local.jpg) center no-repeat;
  background-size: cover;
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  left: 10%;
  bottom: 23%;
  margin: auto;
  width: 50vw;
  z-index: 1;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt {
    width: 60vw;
    bottom: -14%;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    width: 100%;
    bottom: -27%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    width: 60%;
    bottom: -7%;
  }
}

.mainwrap .main_txt h1 {
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 30px;
  color: #ffffff;
  text-align: left;
  line-height: 1.5em;
  position: relative;
  white-space: nowrap;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt h1 {
    font-size: 20px !important;
  }
}

.mainwrap .main_txt h1:before {
  content: '';
  width: 538px;
  height: 350px;
  display: block;
  background: #f29425;
  position: absolute;
  bottom: -70%;
  left: -7%;
  z-index: -1;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt h1:before {
    width: 300px;
    height: 200px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt h1:before {
    width: 380px;
    height: 260px;
    bottom: -43%;
  }
}

.mainwrap .main_txt h1:after {
  content: '';
  width: 538px;
  height: 350px;
  display: block;
  background: rgba(190, 223, 60, 0.7);
  position: absolute;
  top: -87%;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt h1:after {
    width: 300px;
    height: 200px;
    top: -70%;
    right: 4%;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt h1:after {
    width: 300px;
    height: 200px;
    top: -70%;
    right: 16%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt h1:after {
    width: 380px;
    height: 260px;
    top: -46%;
    right: 15%;
  }
}

.mainwrap .main_txt h1 span {
  font-size: 40px;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt h1 span {
    font-size: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt h1 span {
    font-size: 30px;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  color: #fff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 1.5em;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  background: rgba(51, 51, 51, 0.3);
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    margin-bottom: 50px;
    font-size: 2.4em;
  }
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #fff81f;
  border-radius: 10px;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 10px;
  color: #f29425;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #f29425;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background: url(../images/common/outer_bg.jpg);
  background-size: cover;
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  padding-top: 150px !important;
  background: rgba(255, 255, 255, 0.6);
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right {
    display: block;
  }
  .overlayMenu > .outer .sp_hed_right .telwrap {
    position: relative;
    max-width: 290px;
    margin: 0 auto;
    border: 2px solid #fff;
    padding: 16px 16px 16px 30px;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 {
    font-size: 1.3em;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #333;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 span {
    display: block;
    font-size: 13px;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 a {
    color: #333;
    font-weight: bold;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 img {
    margin-right: 7px;
  }
  .overlayMenu > .outer .sp_hed_right .time {
    color: #ffffff;
    background: #f29425;
    font-size: 0.8em;
    max-width: 300px;
    margin: 5px auto 0;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    background: url(../images/common/cont_btn_bg.png) no-repeat;
    background-size: cover;
    color: #ffffff;
    font-size: 1.3em;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
    max-width: 300px;
    margin: 15px auto 0;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    font-size: 1em;
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a img {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a img {
    max-width: calc(100% / 7);
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

.overlayMenu > .outer li a {
  color: #333;
  padding: 10px;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

.overlayMenu .onlineShops {
  margin-top: 42px;
}

.overlayMenu .corporateLink {
  margin-top: 10px;
  height: 55px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background: url(../img/common/bg_corporate-site.jpg) no-repeat center;
  background-size: cover;
}

.overlayMenuTitle {
  display: block;
  margin-top: 64px;
  font-size: 21px;
  font-weight: bold;
  color: #016411;
  text-align: center;
  letter-spacing: 0.1em;
  position: relative;
}

.overlayMenuTitle:after {
  content: "";
  margin: auto;
  height: 22px;
  width: 100px;
  background-size: contain;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.overlayMenuList {
  margin-top: 64px;
  font-size: 16px;
  font-weight: bold;
  color: #016411;
  letter-spacing: 0.1em;
}

.overlayMenuList li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: no-repeat right center;
}

.overlayMenuList li + li {
  margin-top: 22px;
}

.overlayMenuList li:after {
  content: "";
  margin: 0 40px 0 1em;
  background: url(../img/common/bg_dots01.png) repeat-x left center;
  background-size: 3px auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.overlayMenuList li:nth-of-type(2) {
  background-image: url(../img/common/img_menu-orange01.png);
  background-size: auto 23px;
}

.overlayMenuList li:nth-of-type(1),
.overlayMenuList li:nth-of-type(3) {
  background-image: url(../img/common/img_menu-orange02.png);
  background-size: 22.5px 19.5px;
}

.overlayMenuList li:nth-of-type(4) {
  background-image: url(../img/common/img_menu-orange03.png);
  background-size: 21px 25px;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

.fixed_cont_menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(58, 188, 174, 0.7);
  padding: 10px;
  z-index: 1;
  border-top: 5px solid #f29425;
}

.fixed_cont_menu .pccont_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap {
    padding: 0;
  }
}

.fixed_cont_menu .pccont_btn_wrap li {
  margin: 0 5px;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap li {
    margin: 0 5px;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background: #f29425;
}

footer#global_footer .inner {
  padding: 70px 10px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  padding: 0;
  width: 200px;
}

footer#global_footer #nav_footer li {
  text-align: left;
  position: relative;
}

footer#global_footer #nav_footer li:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 13px;
  /*サイズ*/
  left: -5px;
  /*アイコンの位置*/
  top: 3px;
  /*アイコンの位置*/
  color: #fff81f;
  /*アイコン色*/
  font-weight: 900;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 2px 10px;
  display: block;
  color: #ffffff;
  font-weight: bold;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 100px;
  }
}

.small_bg {
  background: #FC3705;
}

.ft_L {
  text-align: left;
  color: #ffffff;
  line-height: 1.3em;
}

.ft_L .address {
  margin-top: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

.ft1wrap {
  background: #fff81f;
}

@media only screen and (max-width: 640px) {
  #footer1 {
    padding: 20px !important;
  }
}

#footer1 .telwrap {
  margin-right: 30px;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  #footer1 .telwrap {
    margin-right: 0;
    padding: 0;
  }
}

#footer1 .telwrap img {
  margin-right: 7px;
}

#footer1 .telwrap .tel2 {
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
  justify-content: center;
}

#footer1 .telwrap .tel2 a {
  color: #333;
  font-weight: bold;
}

#footer1 .telwrap .ft_time {
  font-size: 13px;
  display: block;
  background: #f29425;
  color: #ffffff;
  margin-top: 5px;
  font-weight: bold;
}

#footer1 .ftcont a {
  background: url(../images/common/cont_btn_bg.png) no-repeat;
  background-size: cover;
  color: #ffffff;
  font-size: 1.3em;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont a {
    font-size: 1em;
    padding: 10px;
    margin-top: 15px;
  }
}

#footer1 .ftcont a img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont a img {
    max-width: calc(100% / 7);
  }
}

.ftcont_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .ftcont_wrap {
    display: block;
  }
}

#footer2 {
  display: flex;
  justify-content: space-between;
}

.ftcont_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 640px) {
  .ftcont_box {
    display: block;
  }
}

.ftcont_box .keiji a {
  background: #3554d7;
  padding: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  border-radius: 5px;
  display: block;
  margin-right: 15px;
}

@media only screen and (max-width: 640px) {
  .ftcont_box .keiji a {
    margin-right: 0;
  }
}

.ftcont_box .koutsuu a {
  background: #1da941;
  padding: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  border-radius: 5px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .ftcont_box .koutsuu a {
    margin-top: 15px;
  }
}

.ftmap iframe {
  width: 100%;
  height: 250px;
}

/* box */
.top_txt {
  max-width: 750px;
  margin: 0 auto;
  line-height: 2em;
}

.h2_01 h2 {
  font-size: 1.8em;
  font-weight: 900;
  line-height: 1.5em;
  color: #fff81f;
  text-shadow: 5px 5px 0 #696969;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: italic;
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 {
    text-align: center !important;
    line-height: 1.2em;
    font-size: 2em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 2em;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .h2_01 h2 {
    font-size: 2em;
  }
}

.h2_01 h2 br:nth-of-type(3) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 br:nth-of-type(3) {
    display: block;
  }
}

.h2_03 h2 {
  font-size: 1.8em;
  font-weight: 900;
  line-height: 1.5em;
  color: #fc3605;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.h2_03 h2 br {
  display: none;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 br {
    display: block;
  }
}

.h2_03 h2:before {
  content: url(../images/common/midashi_L.png);
  margin-right: 10px;
}

.h2_03 h2:after {
  content: url(../images/common/midashi_R.png);
  margin-left: 10px;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    text-align: center !important;
    line-height: 1.2em;
    font-size: 1.5em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 2em;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .h2_03 h2 {
    font-size: 2em;
  }
}

.h2_04 h2 {
  font-size: 1.8em;
  font-weight: 900;
  line-height: 1.5em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fc3605;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 2em;
  }
}

.txt_top {
  color: #ffffff;
}

.txt {
  line-height: 2em;
}

.btn a {
  display: block;
  padding: 10px 0;
  color: #333;
  max-width: 415px;
  font-weight: bold;
  border-radius: 5px;
  border: 2px solid #333;
  position: relative;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .btn a {
    max-width: 100%;
  }
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 2%;
  top: 23%;
  color: #333;
  /*アイコン色*/
}

.sec_01wrap {
  background: url(../images/common/cont1_bg.jpg) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.sec_01 {
  color: #333;
  padding: 120px 10px;
}

.btnbox {
  justify-content: center;
}

.btnbox .box {
  width: 24%;
  text-align: center;
  margin: 5px;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .btnbox .box {
    width: 35%;
  }
}

@media only screen and (max-width: 834px) {
  .btnbox .box {
    width: 35%;
  }
}

.btnbox .box a {
  display: block;
  padding: 10px 0;
  color: #ffffff;
  max-width: 300px;
  margin: 0 auto;
  font-weight: bold;
  background: url(../images/common/btn_bg.gif);
  position: relative;
}

.btnbox .box a:after {
  content: '→';
  position: absolute;
  right: 3%;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .btnbox .box {
    width: 100% !important;
    margin: 5px;
  }
}

.btnbox2 {
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .btnbox2 .box {
    width: 65% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 .box {
    width: 32%;
  }
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 41%;
}

.sec_02 .flxR {
  width: 52%;
}

.txt_back div {
  font-size: 1.5em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  color: #ffffff;
  background: #f29425;
  padding: 36px 10px;
}

@media only screen and (max-width: 640px) {
  .txt_back div {
    font-size: 1.25em;
  }
}

.sec_03wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #fff81f;
  padding: 60px 10px;
}

.sec_03 .tel_cont ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_03 .tel_cont ul {
    display: block;
  }
}

.sec_03 .tel_cont li {
  float: none !important;
}

.sec_03 .tel_cont .tel2 {
  font-size: 1.8em;
  font-weight: bold;
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_03 .tel_cont .tel2 {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.sec_03 .tel_cont .tel2 a {
  font-weight: bold;
  color: #333;
}

.sec_03 .tel_cont span {
  background: #f29425;
  color: #ffffff;
  font-size: 13px;
  display: block;
  text-align: center;
  margin-top: 5px;
}

.sec_03 .tel_cont .cont_btn a {
  background: url(../images/common/cont_btn_bg.png) no-repeat;
  background-size: cover;
  padding: 15px 30px;
  display: block;
  color: #ffffff;
  font-size: 1.25em;
  font-weight: bold;
  border-radius: 10px;
}

.sec_04wrap {
  background: url(../images/common/support_bg01.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
}

.sec_05wrap {
  background: url(../images/common/support_bg02.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
  margin-top: -125px;
}

@media only screen and (max-width: 640px) {
  .sec_04, .sec_05, .sec_06, .sec_07, .sec_08, .sec_09, .sec_10 {
    background: rgba(255, 255, 255, 0.8);
    padding: 30px;
  }
}

.sec_06wrap {
  background: url(../images/common/support_bg03.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
  margin-top: -125px;
}

.sec_07wrap {
  background: url(../images/common/support_bg04.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
  margin-top: -125px;
}

.sec_08wrap {
  background: url(../images/common/support_bg05.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
  margin-top: -125px;
}

.sec_09wrap {
  background: url(../images/common/support_bg06.png) no-repeat center;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 175px 10px;
  margin-top: -125px;
}

.sec_05, .sec_07, .sec_09 {
  margin-left: 55%;
}

@media only screen and (max-width: 640px) {
  .sec_05, .sec_07, .sec_09 {
    margin-left: 0 !important;
  }
}

.gleywrap {
  background: #f7f7f7;
  padding: 90px 10px;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-top: 120px;
}

@media only screen and (max-width: 640px) {
  .gleywrap {
    padding: 50px 10px;
    margin-top: 80px;
  }
}

.txt_gley div {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.2em;
  position: relative;
}

.txt_gley div:before {
  content: url(../images/common/midashi_cont.png);
  position: absolute;
  left: 9%;
  top: -71%;
}

.sec_10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_10 {
    display: block;
  }
  .sec_10 .flxL {
    width: 100% !important;
  }
  .sec_10 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_10 .flxL {
  width: 41%;
}

.sec_10 .flxR {
  width: 55%;
}

.sec_11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_11 {
    display: block;
  }
  .sec_11 .flxL {
    width: 100% !important;
  }
  .sec_11 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_11 .flxL {
  width: 41%;
}

.sec_11 .flxR {
  width: 55%;
}

.sec_12 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_12 {
    display: block;
  }
  .sec_12 .flxL {
    width: 100% !important;
  }
  .sec_12 .flxR {
    width: 100% !important;
    margin-top: 50px;
  }
}

.sec_12 .flxL {
  width: 48%;
}

.sec_12 .flxR {
  width: 48%;
}

.menubox {
  justify-content: center;
  color: #333;
}

.menubox .box {
  width: 30%;
  text-align: center;
  margin: 15px;
}

@media only screen and (max-width: 640px) {
  .menubox .box {
    width: 40%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .box {
    width: 28%;
  }
}

.menubox .box h3 {
  background: #FF768B;
  padding: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  max-width: 280px;
  margin: 15px auto 0;
}

.menubox .box article div {
  text-align: left;
  max-width: 285px;
  margin: 0 auto;
}

.list li {
  position: relative;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
}

.list li:before {
  content: url(../images/common/check_icon.png);
  margin-right: 10px;
}

.btn_list li {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .btn_list li {
    width: 100%;
    margin: 5px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btn_list li {
    width: 33%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .btn_list li {
    width: 33%;
  }
}

.btn_list li a {
  background: #f29425;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  width: 140px;
}

@media only screen and (max-width: 640px) {
  .btn_list li a {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btn_list li a {
    width: 118px;
    font-size: 13px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .btn_list li a {
    width: 118px;
    font-size: 13px;
  }
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 20% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 240px !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  position: relative;
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    height: 200px;
  }
  .bnrbox .box:nth-of-type(2) {
    margin-top: 30px;
  }
  .bnrbox .box:nth-of-type(3) {
    margin-top: 30px;
  }
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.Item {
  margin-top: 20px;
  /*なくてもOK*/
  width: 40%;
  /*お好みで*/
}

@media screen and (max-width: 768px) {
  .Item {
    width: 40%;
  }
}

.Item-Box {
  overflow: hidden;
}

.Item-Box-ImgBox {
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-transform: translate3d(-100.5%, 0, 0);
  transform: translate3d(-100.5%, 0, 0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.Item-Box.isShow .Item-Box-ImgBox {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.Item-Box-ImgBox:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #000;
  /*黒ブロックの色指定*/
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.Item-Box.isShow .Item-Box-ImgBox:before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.Item-Box-ImgBox-Img {
  width: 100%;
  padding-top: 62.5%;
  /*画像比率指定*/
  transition-duration: 4s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.newswrap .news {
  height: 250px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-left: 0;
    font-size: 16px;
  }
}

.newswrap .news dt {
  border: none !important;
  color: #ffffff;
  padding: 0 !important;
  font-weight: bold;
  display: block !important;
  width: 150px !important;
  background: #f29425 !important;
  padding: 5px;
  text-align: center;
}

.newswrap .news dd {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
}

.newswrap .news dl {
  border-bottom: 1px dashed #333 !important;
  padding: 20px 0 !important;
  align-items: flex-start;
  display: block !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.top_blog {
  width: 100%;
}

.blog_list {
  height: 250px;
  overflow-y: auto;
  border: none !important;
  width: 100%;
}

.blog_list .detail {
  display: none;
}

.blog_list .blog_photo {
  display: none;
}

.blog_list .blog_photo img {
  width: 180px !important;
  height: auto !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media only screen and (max-width: 640px) {
  .blog_list .blog_photo img {
    width: 100% !important;
  }
}

.blog_list .blog_text {
  font-size: 16px !important;
  padding: 20px 0 !important;
}

@media only screen and (max-width: 834px) {
  .blog_list .blog_text {
    display: block !important;
    width: 100% !important;
  }
}

.blog_list .blog_text h3 {
  width: 100%;
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 640px) {
  .blog_list .blog_text h3 {
    width: 100% !important;
  }
}

.blog_list .blog_text h3 a {
  color: #f29425 !important;
}

.blog_list .blog_text h3 a:hover {
  text-decoration: underline;
}

.blog_list .blog_date {
  width: 150px !important;
  color: #ffffff !important;
  background: #f29425 !important;
  text-align: center;
}

div.blog_list > div {
  border-bottom: 1px dashed #333 !important;
}

@media only screen and (max-width: 834px) {
  div.blog_list > div {
    flex-direction: row !important;
  }
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.h2_05 h2 {
  font-size: 1.8em;
  font-weight: 900;
  line-height: 1em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-left: 5px solid #f29425;
  border-bottom: 1px solid #fff81f;
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 1.5em;
  }
}

.h3_02 h3 {
  font-size: 1.25em;
  font-weight: bold;
  background: #f29425;
  color: #ffffff;
  padding: 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: italic;
}

.h3_02 h3 span {
  font-size: 30px;
  color: #fff81f;
}

.menubox {
  position: relative;
  max-height: 710px;
  max-width: 800px;
  width: 100%;
  height: 110vh;
  margin: 0 auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox {
    max-width: 710px;
    max-height: 710px;
    margin: 0 auto;
  }
}

.menubox .cont_center {
  background: url(../images/contents/point_7_img.png) no-repeat center;
  background-size: 80%;
  padding: 300px 0;
}

.menubox .cont_center a {
  display: inline-block;
  padding: 10% 5%;
  color: #333;
}

.menubox .cont_center a .title_cont {
  color: #333;
  line-height: 1.2em;
}

.menubox .cont_center a span {
  display: block;
  font-size: 1em;
  font-weight: bold;
}

.menubox .menu_cont:hover .title_cont {
  color: #ffffff;
  line-height: 1.5em;
}

.menubox .cont_center:hover .title_cont {
  color: #f29425;
  line-height: 1.5em;
}

.menubox .menu_cont {
  position: absolute;
  border: 1px solid #f29425;
  color: #333;
  width: 13vw;
  min-width: 200px;
  height: 13vw;
  min-height: 200px;
  margin: auto;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  transition-duration: .6s;
  background: #fff;
  z-index: 9999;
}

.menubox .menu_cont > div {
  height: 100%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont {
    min-height: 180px;
    min-width: 180px;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont {
    min-height: 150px;
    min-width: 150px;
  }
}

.menubox .menu_cont:hover {
  background: #f29425;
  color: #ffffff;
}

.menubox .menu_cont a {
  height: 100%;
  width: 100%;
  display: block;
}

.menubox .menu_cont a .title_cont {
  color: #333;
  line-height: 1.5em;
  transform: translateY(150%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont a .title_cont {
    transform: translateY(120%);
  }
}

.menubox .menu_cont a span {
  display: block;
  font-size: 1em;
  font-weight: bold;
}

.menubox .menu_cont:nth-of-type(1) {
  left: 0;
  right: 0;
  top: 3%;
}

.menubox .menu_cont:nth-of-type(1) a {
  left: 0;
  right: 0;
  top: 3%;
}

.menubox .menu_cont:nth-of-type(1) a .title_cont {
  transform: translateY(100%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(1) {
    top: 5%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(1) {
    top: 13%;
  }
}

.menubox .menu_cont:nth-of-type(2) {
  right: 12%;
  top: 16%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(2) {
    right: 12%;
    top: 16%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(2) {
    right: 4%;
    top: 23%;
  }
}

.menubox .menu_cont:nth-of-type(2) a .title_cont {
  transform: translateY(80%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(2) a .title_cont {
    transform: translateY(80%);
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(2) a .title_cont {
    transform: translateY(50%);
  }
}

.menubox .menu_cont:nth-of-type(3) {
  right: 1%;
  bottom: 20%;
}

.menubox .menu_cont:nth-of-type(3) .sub {
  font-size: 11px;
  letter-spacing: 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(3) {
    right: 0%;
    bottom: 25%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(3) {
    right: -1%;
    bottom: 30%;
  }
}

.menubox .menu_cont:nth-of-type(3) a .title_cont {
  transform: translateY(80%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(3) a .title_cont {
    transform: translateY(80%);
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(3) a .title_cont {
    transform: translateY(30%);
  }
}

.menubox .menu_cont:nth-of-type(4) {
  right: 19%;
  bottom: -11%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(4) {
    right: 21%;
    bottom: -2%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(4) {
    right: 17%;
    bottom: 10%;
  }
}

.menubox .menu_cont:nth-of-type(4) a .title_cont {
  transform: translateY(100%);
}

.menubox .menu_cont:nth-of-type(5) {
  left: 19%;
  bottom: -11%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(5) {
    left: 21%;
    bottom: -2%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(5) {
    left: 17%;
    bottom: 10%;
  }
}

.menubox .menu_cont:nth-of-type(5) a .title_cont {
  transform: translateY(80%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(5) a .title_cont {
    transform: translateY(80%);
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(5) a .title_cont {
    transform: translateY(30%);
  }
}

.menubox .menu_cont:nth-of-type(6) {
  left: 1%;
  bottom: 20%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(6) {
    left: 0%;
    bottom: 25%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(6) {
    left: -1%;
    bottom: 30%;
  }
}

.menubox .menu_cont:nth-of-type(6) a .title_cont {
  transform: translateY(100%);
}

.menubox .menu_cont:nth-of-type(7) {
  left: 12%;
  top: 16%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .menubox .menu_cont:nth-of-type(7) {
    left: 12%;
    top: 16%;
  }
}

@media only screen and (max-width: 640px) {
  .menubox .menu_cont:nth-of-type(7) {
    left: 4%;
    top: 23%;
  }
}

.menubox .menu_cont:nth-of-type(7) a .title_cont {
  transform: translateY(100%);
}

.txt {
  line-height: 2em;
}

.cont_tel div {
  font-size: 1.8em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.25em;
}

.cont_tel div span {
  font-size: 18px;
}

.cont_tel a {
  color: #f29425;
  font-weight: bold;
}

.cont_form {
  padding: 30px;
  background: #f7f7f7;
}

.cont_form dd .textarea {
  height: 250px;
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.h3_point01 h3 {
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.h3_point01 h3:before {
  content: url(../images/common/merit01.png);
  margin-right: 10px;
}

.h3_point02 h3 {
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.h3_point02 h3:before {
  content: url(../images/common/merit02.png);
  margin-right: 10px;
}

.h3_point03 h3 {
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.h3_point03 h3:before {
  content: url(../images/common/merit03.png);
  margin-right: 10px;
}

.h3_point04 h3 {
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.h3_point04 h3:before {
  content: url(../images/common/merit04.png);
  margin-right: 10px;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.cont_01 .flxL {
  width: 48%;
}

.cont_01 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_01 {
    display: block;
  }
  .cont_01 .flxL {
    width: 100% !important;
  }
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_02 {
  display: flex;
  justify-content: space-between;
}

.cont_02 .flxL {
  width: 48%;
}

.cont_02 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_02 {
    display: block;
  }
  .cont_02 .flxL {
    width: 100% !important;
  }
  .cont_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.cont_03 .flxL {
  width: 48%;
}

.cont_03 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_03 {
    display: block;
  }
  .cont_03 .flxL {
    width: 100% !important;
  }
  .cont_03 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.listbox {
  background: #f7f7f7;
  padding: 30px;
}

.dl_01 dt {
  align-items: center !important;
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
}

.dl_01 dd {
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .tel_list li {
    width: 100%;
  }
}

.tel_list li .tel2 {
  font-size: 2em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

@media only screen and (max-width: 640px) {
  .tel_list li .tel2 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .tel_list li .tel2 {
    font-size: 1.25em;
    margin-right: 0;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .tel_list li .tel2 {
    font-size: 1.25em;
  }
}

.tel_list li .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .tel_list li .tel2 img {
    max-width: calc(100% / 7);
  }
}

.tel_list li .tel2 a {
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .tel_list li .cont {
    margin-top: 15px;
  }
}

.tel_list li .cont a {
  background: #fff81f;
  color: #ffffff;
  font-size: 1.3em;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .tel_list li .cont a {
    font-size: 1.25em;
    padding: 10px;
  }
}

.tel_list li .cont a img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  .tel_list li .cont a img {
    max-width: calc(100% / 7);
  }
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #f7f7f7;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #f29425;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #fff81f;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.dl_03 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
  font-weight: bold;
}

.dl_03 dd {
  border: none !important;
  width: 100% !important;
  text-align: right;
  padding: 0 !important;
}

.dl_03 dl {
  border-bottom: 1px dashed #ccc;
  display: block !important;
  padding: 10px 0 !important;
}

.cont_form a {
  color: #333;
}

.cont_form a:hover {
  text-decoration: underline;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.imgbox {
  justify-content: space-between;
}

.imgbox .box {
  margin: 10px;
  width: 30%;
}

.imgbox .box img {
  width: 100% !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box img {
    height: 150px !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.dl_02 dt {
  border: none !important;
  background: #fff81f !important;
  justify-content: center !important;
  flex-direction: inherit !important;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .dl_02 dt {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
}

.dl_02 dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dd {
    width: 100% !important;
    display: block !important;
  }
}

.dl_02 dl {
  border-bottom: 1px dashed #ccc !important;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.btnlist li {
  width: 33%;
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    width: 100% !important;
  }
}

.btnlist li a {
  background: #FF768B;
  padding: 15px;
  display: block;
  color: #ffffff;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #f29425;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #f29425;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
